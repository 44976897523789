
input:focus, textarea:focus, select:focus{
  outline: none;
}

.messages_input {
  height: 40px;
  width: 170px;
  padding: 0 5px;
  border: 1px solid #e6e5e8;
  background-color: #282C34;
  border-style:inset;
  position: absolute;
  margin: 0;
  right: 21%;
  bottom: 21%;
  font-size: 16px;
  color: #e6e5e8;
  border-radius: 50px;
}

.message_displayer {
  min-width: 50px;
  min-height: 40px;
  max-width: 195px;
  padding: 10px 30px;
  border: 1px solid #e6e5e8;
  background-color: #282C34;
   position: absolute;
  margin: 0;
  right: 21%;
  bottom: 40%;
  font-size: 16px;
  color: #e6e5e8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease;
}