.login-page_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.login-page_login_box {
  width: 350px;
  height: 500px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}


.login-page_logo_container {
  width: 80%;
  display: flex;
  justify-content: center;
}

.login-page_logo_image {
  width: 200px;
}

.login-page_title_container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-page_input_container {
  width: 80%;
  display: flex;
  justify-content: center;
}

.login-page_input {
  width: 150px;
  border-radius: 50px;
  height: 30px;
  outline:none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 10px;
}

.login-page_button_container {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page_button {
  border-radius: 50px;
  outline: none;
  border: 1px solid #e6e5e8;
  font-size: 16px;
  padding: 10px 10px;
  width: 170px;
  transition: 0.3s;
}

.login-page_button:hover {
  background-color: #8706f0;
  opacity: 0.5;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #e6e5e8;
  text-align: center;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #e6e5e8;
  text-align: center;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #e6e5e8;
  text-align: center;
}
:-moz-placeholder { /* Firefox 18- */
  color: #e6e5e8;
  text-align: center;
}
