.dashboard_container {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.dashboard_left_section {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dashboard_right_section {
  width: 40%;
  height: 100%;
}

.dashboard_content_container {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
}

.dashboard_rooms_container {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
}

.dashboard_info_text_container {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}

.dashboard_info_text_title {
  font-size: 30px;
}

.dashboard_info_text_description {
  margin-top: 20px;
  font-size: 20px;
}

.dashboard_active_users_list {
  height: 80%
}

.dashboard_logo_container {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.dashboard_logo_image {
  width: 60%;
}